import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import quoteClient from 'api/quoteClient';
import React, { useEffect } from 'react';
import { getQueryParam } from 'helpers/getQueryParam';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { isRenewal, UPDATE_QUOTE, useQuote } from 'state/quote/quote';
import useDispatch from 'state/useDispatch';

/**
 * Initialises the Quote state in redux on page load. This is used for the quote summary page
 * and beyond.
 *
 * Uses standard error handling, when the quote is not found, or the user is not authorised to
 * view it.
 */
const LoadQuoteWrapper: React.FC<{ location: Location }> = ({ location, children }) => {
  const quote = useQuote();
  const dispatch = useDispatch();
  const { requestHandler, isLoading } = useApiRequestHandler();
  const quoteNumber =
    getQueryParam(location, 'quoteNumber') ?? quote?.quoteNumber ?? null;

  useEffect(() => {
    const fetchQuote = async (): Promise<void> => {
      const fetchedQuote = quoteNumber
        ? await quoteClient.getQuote(quoteNumber)
        : await quoteClient.getCurrentQuote();
      dispatch({ type: UPDATE_QUOTE, quote: fetchedQuote });
    };
    if (
      !quote ||
      /* If the user uses browser back, they could get to a Q&B page with a renewal, 
       so we load the quote again if this is the case */
      isRenewal(quote) ||
      (quoteNumber && quote.quoteNumber !== quoteNumber)
    ) {
      dispatch({ type: UPDATE_QUOTE, quote: null });
      requestHandler(fetchQuote);
    }
  }, [dispatch, quote, quoteNumber, requestHandler]);

  return (
    <>
      {isLoading && <LoadingOverlay loadingMessage="Loading quote" />}
      {children}
    </>
  );
};

export default LoadQuoteWrapper;
