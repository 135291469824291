import { FullWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { avoidBreakOnPrint, hideOnPrint } from 'helpers/cssPrintHelpers';

export const DividerWithPadding = styled(FullWidthDivider)`
  margin-top: ${spacing(6)};
  margin-bottom: ${spacing(6)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(8)};
    margin-bottom: ${spacing(8)};
  `}
`;

export const AvoidBreakContainer = styled.section`
  ${avoidBreakOnPrint}
`;

export const HideOnPrint = styled.div`
  ${hideOnPrint}
`;

export const PriceBannerWhitespace = styled.div`
  height: ${spacing(8)};
`;
