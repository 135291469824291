import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import Layout from 'components/Layout';
import QuoteSummaryPage from 'components/QuoteSummary';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { usePageTracking } from 'helpers/usePageTracking';
import { useQuote } from 'state/quote/quote';
import { CsAddOn, CsMeta } from 'types/contentStack';
import { PriceBannerWhitespace } from './styles';

const STEP = 5;

type QuoteSummaryProps = {
  data: {
    csQuoteSummary: {
      meta: CsMeta;
    };
    allCsAddOn: {
      nodes: CsAddOn[];
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csQuoteSummary {
      meta {
        meta_title
      }
      heading
      next_button_text
    }
  }
`;

const QuoteSummary: React.FC<QuoteSummaryProps> = ({
  data: {
    csQuoteSummary: { meta },
  },
  location,
}) => {
  const quote = useQuote(true);

  useEffect(() => {
    if (quote) {
      if (quote.status !== 'Quote') {
        throw new Error('Returned quote does not have status "Quote"');
      }
    }
  }, [quote]);

  usePageTracking(meta.meta_title, !!quote);
  useCheckoutTracking(STEP, quote, 'Quote and Buy', true);

  return (
    <LoadQuoteWrapper location={location}>
      <Layout meta={meta} quoteAndBuyStep={STEP} pageType="quoteAndBuy">
        {quote && <QuoteSummaryPage quote={quote} />}
      </Layout>
      {/* Need to create a space at the bottom of the page for the price banner so that it does not sit over the footer */}
      <PriceBannerWhitespace />
    </LoadQuoteWrapper>
  );
};

export default QuoteSummary;
